import building from "../images/OFFICE/building.jpg";
import conference from "../images/OFFICE/conference.jpg";
import entry from "../images/OFFICE/entry.jpg";
import hall from "../images/OFFICE/hall.jpg";
import store from "../images/OFFICE/store.jpg";
import cabin from "../images/OFFICE/cabin.jpg";
import employee from "../images/OFFICE/employee.jpg";
import group from "../images/OFFICE/group.jpg";
import office from "../images/OFFICE/office.jpg";
import _20161209_132654 from "../images/CES - 125 MW TPP -SENEGAL/20161209_132654.jpg";
import _20161209_150007 from "../images/CES - 125 MW TPP -SENEGAL/20161209_150007.jpg";
import _20161209_132520 from "../images/CES - 125 MW TPP -SENEGAL/20161209_132520.jpg";
import SENEGAL_24 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 24.jpg";
import SENEGAL_11 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 11.jpg";
import SENEGAL_25 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 25.jpg";
import SENEGAL_12 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 12.jpg"
import SENEGAL_26 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 26.jpg"
import _20161209_150014 from "../images/CES - 125 MW TPP -SENEGAL/20161209_150014.jpg"
import SENEGAL_27 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 27.jpg"
import SENEGAL_14 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 14.jpg"
import SENEGAL_28 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 28.jpg"
import _20161209_151719 from "../images/CES - 125 MW TPP -SENEGAL/20161209_151719.jpg"
import SENEGAL_15 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 15.jpg"
import SENEGAL_2 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 2.jpg"
import IMG_20170103_WA0007 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0007.jpg"
import SENEGAL_16 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 16.jpg"
import SENEGAL_3 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 3.jpg"
import IMG_20170103_WA0008 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0008.jpg"
import SENEGAL_17 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 17.jpg"
import SENEGAL_4 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 4.jpg"
import IMG_20170103_200009 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0009.jpg"
import SENEGAL_18 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 18.jpg"
import SENEGAL_5 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 5.jpg"
import IMG_20170103_200013 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0013.jpg"
import SENEGAL_6 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 6.jpg"
import IMG_20170103_200014 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0014.jpg"
import SENEGAL_7 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 7.jpg"
import IMG_20170103_200018 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0018.jpg"
import SENEGAL_20 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 20.jpg"
import SENEGAL_8 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 8.jpg"
import IMG_20170103_200022 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170103-WA0022.jpg"
import SENEGAL_21 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 21.jpg"
import SENEGAL_9 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 9.jpg"
import IMG_20170118_200024 from "../images/CES - 125 MW TPP -SENEGAL/IMG-20170118-WA0024.jpg"
import SENEGAL_22 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 22.jpg"
import MCC from "../images/CES - 125 MW TPP -SENEGAL/MCC.jpg"
import SENEGAL_23 from "../images/CES - 125 MW TPP -SENEGAL/SENEGAL - 23.jpg"
import DESAL_1 from "../images/DESAL - 100 MLD/DESAL - 1.jpg"
import DESAL_4 from "../images/DESAL - 100 MLD/DESAL - 4.jpg"
import DESAL_7 from "../images/DESAL - 100 MLD/DESAL - 7.jpg"
import DESAL_2 from "../images/DESAL - 100 MLD/DESAL - 2.jpg"
import DESAL_5 from "../images/DESAL - 100 MLD/DESAL - 5.jpg"
import DESAL_8 from "../images/DESAL - 100 MLD/DESAL - 8.jpg"
import DESAL_3 from "../images/DESAL - 100 MLD/DESAL - 3.jpg"
import DESAL_6 from "../images/DESAL - 100 MLD/DESAL - 6.jpg"
import DESAL_9 from "../images/DESAL - 100 MLD/DESAL - 9.jpg"
import FGUTPP_10 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 10.jpg"
import FGUTPP_16 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 16.jpg"
import FGUTPP_21 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 21.jpg"
import FGUTPP_6 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 6.jpg"
import FGUTPP_11 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 11.jpg"
import FGUTPP_17 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 17.jpg"
import FGUTPP_22 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 22.jpg"
import FGUTPP_7 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 7.jpg"
import FGUTPP_12 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 12.jpg"
import FGUTPP_18 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 18.jpg"
import FGUTPP_2 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 2.jpg"
import FGUTPP_8 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 8.jpg"
import FGUTPP_13 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 13.jpg"
import FGUTPP_19 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 19.jpg"
import FGUTPP_3 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 3.jpg"
import FGUTPP_9 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 9.jpg"
import FGUTPP_14 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 14.jpg"
import FGUTPP_1 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 1.jpg"
import FGUTPP_4 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 4.jpg"
import FGUTPP_15 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 15.jpg"
import FGUTPP_20 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 20.jpg"
import FGUTPP_5 from "../images/FGUTPP - 1 x 500 MW/FGUTPP - 5.jpg"
import _20161007_170015_ from "../images/PUJA/20161007_170015.jpg"
import _20161007_170051_ from "../images/PUJA/20161007_170051.jpg"
import _20161007_170911_ from "../images/PUJA/20161007_170911.jpg"
import _20161007_170021_ from "../images/PUJA/20161007_170021.jpg"
import _20161007_170053_ from "../images/PUJA/20161007_170053.jpg"
import _166KV_SWITCHGEAR from "../images/RVUNL - 160 MW RAMGARH/1.6.6KV SWITCHGEAR.jpg"
import GTG_BLDG_GTG_TRANSFORMER from "../images/RVUNL - 160 MW RAMGARH/GTG BLDG. GTG TRANSFORMER.jpg"
import _1BYPASS_STACK from "../images/RVUNL - 160 MW RAMGARH/1.BYPASS STACK.jpg"
import GTGBuilding_Bypass_Stack from "../images/RVUNL - 160 MW RAMGARH/GTGBuilding_Bypass Stack.jpg"
import _1GTG from "../images/RVUNL - 160 MW RAMGARH/1.GTG.jpg"
import HRSG_GTG_BLDG_BY_PASS_STACKPIPE_RACK from "../images/RVUNL - 160 MW RAMGARH/HRSG ,GTG BLDG., BY PASS STACK,PIPE RACK.jpg"
import _1GT_OFF_BASE_EQUP from "../images/RVUNL - 160 MW RAMGARH/1.GT OFF BASE EQUP..jpg"
import HRSG_MAIN_STACK from "../images/RVUNL - 160 MW RAMGARH/HRSG_MAIN STACK.jpg"
import _1HRSG from "../images/RVUNL - 160 MW RAMGARH/1.HRSG.jpg"
import over_all from "../images/RVUNL - 160 MW RAMGARH/over all.jpg"
import _1UAT___GTG from "../images/RVUNL - 160 MW RAMGARH/1.UAT _ GTG.jpg"
import RVUNL__1 from "../images/RVUNL - 160 MW RAMGARH/RVUNL - 1.jpg"
import _2_20KVswitch_Yard from "../images/RVUNL - 160 MW RAMGARH/220KVswitch Yard.jpg"
import RVUNL__2 from "../images/RVUNL - 160 MW RAMGARH/RVUNL - 2.jpg"
import _2_20KV_Switch_Yard from "../images/RVUNL - 160 MW RAMGARH/220KV Switch Yard.jpg"
import RVUNL from "../images/RVUNL - 160 MW RAMGARH/RVUNL.jpg"
import _160_MVA_GTPnl_charged from "../images/RVUNL - 330 MW DHOLPUR/160 MVA GTPnl charged.jpg"
import _160MVA_GT_U from "../images/RVUNL - 330 MW DHOLPUR/160MVA GT U.jpg"
import _160_MVA_with_Bus_duct_upto_GCB from "../images/RVUNL - 330 MW DHOLPUR/160 MVA with Bus duct upto GCB.jpg"
import _2_20_KV_SYD_2 from "../images/RVUNL - 330 MW DHOLPUR/220 KV SYD2.jpg"
import ACW_Pumps___Piping_works from "../images/RVUNL - 330 MW DHOLPUR/ACW Pumps _ Piping works.jpg"
import Aircompressorbuilding from "../images/RVUNL - 330 MW DHOLPUR/Aircompressorbuilding.jpg"
import Boiler_erection_BHEL from "../images/RVUNL - 330 MW DHOLPUR/Boiler erection BHEL.jpg"
import CCW_Compressor_house from "../images/RVUNL - 330 MW DHOLPUR/CCW Compressor house.jpg"
import CCW_PH_Compressor_room from "../images/RVUNL - 330 MW DHOLPUR/CCW PH  Compressor room.jpg"
import Chimney_BHEL from "../images/RVUNL - 330 MW DHOLPUR/Chimney BHEL.jpg"
import Clarifier_PH_pump_erection from "../images/RVUNL - 330 MW DHOLPUR/Clarifier_PH pump erection.jpg"
import Clarifier_reactor_tank__1 from "../images/RVUNL - 330 MW DHOLPUR/Clarifier reactor tank_1.jpg"
import Clarifier_reactor_tank__2 from "../images/RVUNL - 330 MW DHOLPUR/Clarifier reactor tank_2.jpg"
import Cooling_Tower_comp_2 from "../images/RVUNL - 330 MW DHOLPUR/Cooling Tower comp-2.jpg"
import Cooling_Tower from "../images/RVUNL - 330 MW DHOLPUR/Cooling Tower.jpg"
import Deggased_water_storage_tank__Fab from "../images/RVUNL - 330 MW DHOLPUR/Deggased water storage tank - Fab.jpg"
import DM_plant_area_Pumps from "../images/RVUNL - 330 MW DHOLPUR/DM plant area_Pumps.jpg"
import DM_Plant_Building_with_DM_Tanks___Equipments from "../images/RVUNL - 330 MW DHOLPUR/DM Plant Building with DM Tanks _ Equipments.jpg"
import DM_Plant__Erection_of_Tanks from "../images/RVUNL - 330 MW DHOLPUR/DM Plant - Erection of Tanks.jpg"
import DM_Plant from "../images/RVUNL - 330 MW DHOLPUR/DM Plant.jpg"
import DM_Plant__Pumps_erection from "../images/RVUNL - 330 MW DHOLPUR/DM Plant - Pumps erection.jpg"
import DM_Plant_tanks from "../images/RVUNL - 330 MW DHOLPUR/DM Plant tanks.jpg"
import DM_Plant_vessels from "../images/RVUNL - 330 MW DHOLPUR/DM Plant vessels.jpg"
import DM_Tank_Erection from "../images/RVUNL - 330 MW DHOLPUR/DM Tank Erection.jpg"
import Dm_Tanks_painting from "../images/RVUNL - 330 MW DHOLPUR/Dm Tanks_painting.jpg"
import Fire_Fighting_Line_for_UAT___GT from "../images/RVUNL - 330 MW DHOLPUR/Fire Fighting Line for UAT _ GT.jpg"
import Frontal_Piping from "../images/RVUNL - 330 MW DHOLPUR/Frontal Piping.jpg"
import FRP_Cooling_towers from "../images/RVUNL - 330 MW DHOLPUR/FRP Cooling towers.jpg"
import GCB_with_Busduct_from_GT_to_GCB from "../images/RVUNL - 330 MW DHOLPUR/GCB with Busduct from GT to GCB.jpg"
import GTG_SWGR_Charging_Pooja from "../images/RVUNL - 330 MW DHOLPUR/GTG SWGR Charging Pooja.jpg"
import Lighting_works_Swyd_CR from "../images/RVUNL - 330 MW DHOLPUR/Lighting works Swyd_CR.jpg"
import LP_Piping___Cable_trays from "../images/RVUNL - 330 MW DHOLPUR/LP Piping _ Cable trays.jpg"
import LP_Piping_Cable_trays_Road___Drainage from "../images/RVUNL - 330 MW DHOLPUR/LP Piping, Cable trays, Road _ Drainage.jpg"
import PHE from "../images/RVUNL - 330 MW DHOLPUR/PHE.jpg"
import Pipe_Rack_FDN_BFP_Build_Cooling_Tower_1 from "../images/RVUNL - 330 MW DHOLPUR/Pipe Rack FDN. BFP Build. Cooling Tower1.jpg"
import Pipe_racks_with_LP_piping_erct from "../images/RVUNL - 330 MW DHOLPUR/Pipe racks with LP piping erct.jpg"
import Pipe_Rack_with_erect_of_pipes from "../images/RVUNL - 330 MW DHOLPUR/Pipe Rack with erect of pipes.jpg"
import RWR_Comp__2 from "../images/RVUNL - 330 MW DHOLPUR/RWR_Comp_2.jpg"
import RWR_PH_with_stop_log_gate from "../images/RVUNL - 330 MW DHOLPUR/RWR_PH with stop log gate.jpg"
import STG_B_row_col from "../images/RVUNL - 330 MW DHOLPUR/STG B row col..jpg"
import STG_Building from "../images/RVUNL - 330 MW DHOLPUR/STG Building.jpg"
import STG__Building from "../images/RVUNL - 330 MW DHOLPUR/STG_ Building.jpg"
import STG_Control_room_Deck___turbine_hall from "../images/RVUNL - 330 MW DHOLPUR/STG Control room, Deck _ turbine hall.jpg"
import STG_CR_Floor_EL_107 from "../images/RVUNL - 330 MW DHOLPUR/STG_CR Floor EL107.jpg"
import STG_CR_Floor_EL_1_1_15M from "../images/RVUNL - 330 MW DHOLPUR/STG_CR Floor EL.111.5M.jpg"
import STG_Deck_CMD_visit from "../images/RVUNL - 330 MW DHOLPUR/STG Deck CMD visit.jpg"
import STG_Deck from "../images/RVUNL - 330 MW DHOLPUR/STG_Deck.jpg"
import STG_Machine_Hall from "../images/RVUNL - 330 MW DHOLPUR/STG Machine Hall.jpg"
import STG_MCR___SYD_CR from "../images/RVUNL - 330 MW DHOLPUR/STG_MCR _ SYD_CR.jpg"
import STG_SWGR_Room_Panel_charging_1 from "../images/RVUNL - 330 MW DHOLPUR/STG SWGR Room Panel charging1.jpg"
import Switchyard_with_control_room from "../images/RVUNL - 330 MW DHOLPUR/Switchyard with control room.jpg"
import Swyd_ACDB___Batter_Charger_Pnl_charged from "../images/RVUNL - 330 MW DHOLPUR/Swyd ACDB _ Batter Charger Pnl charged.jpg"
import Swyd_CR_Lighting_works from "../images/RVUNL - 330 MW DHOLPUR/Swyd_CR Lighting works.jpg"
import Swyd_with_control_room from "../images/RVUNL - 330 MW DHOLPUR/Swyd with control room.jpg"
import SYD_Control_room from "../images/RVUNL - 330 MW DHOLPUR/SYD_Control room.jpg"
import SYD_Eqpt_Erection from "../images/RVUNL - 330 MW DHOLPUR/SYD_Eqpt. Erection.jpg"
import SYD_Erection from "../images/RVUNL - 330 MW DHOLPUR/SYD_Erection.jpg"
export const photos = [
  {
    album: "OFFICE",
    urls: [
      building,
      conference,
      entry,
      hall,
      store,
      cabin,
      employee,
      group,
      office
    ]
  },
  {
    album: "CES - 125 MW TPP -SENEGAL",
    urls: [
      _20161209_132520,
      SENEGAL_24,
      _20161209_132654,
      SENEGAL_11,
      SENEGAL_25,
      _20161209_150007,
      SENEGAL_12,
      SENEGAL_26,
      _20161209_150014,
      SENEGAL_27,
      SENEGAL_14,
      SENEGAL_28,
      _20161209_151719,
      SENEGAL_15,
      SENEGAL_2,
      IMG_20170103_WA0007,
      SENEGAL_16,
      SENEGAL_3,
      IMG_20170103_WA0008,
      SENEGAL_17,
      SENEGAL_4,
      IMG_20170103_200009,
      SENEGAL_18,
      SENEGAL_5,
      IMG_20170103_200013,
      SENEGAL_6,
      IMG_20170103_200014,
      SENEGAL_7,
      IMG_20170103_200018,
      SENEGAL_20,
      SENEGAL_8,
      IMG_20170103_200022,
      SENEGAL_21,
      SENEGAL_9,
      IMG_20170118_200024,
      SENEGAL_22,
      MCC,
      SENEGAL_23,
    ],
  },
  {
    album: "DESAL - 100 MLD",
    urls: [
      DESAL_1,
      DESAL_4,
      DESAL_7,
      DESAL_2,
      DESAL_5,
      DESAL_8,
      DESAL_3,
      DESAL_6,
      DESAL_9,
    ],
  },
  {
    album: "FGUTPP - 1 x 500 MW",
    urls: [
      FGUTPP_10,
      FGUTPP_16,
      FGUTPP_21,
      FGUTPP_6,
      FGUTPP_11,
      FGUTPP_17,
      FGUTPP_22,
      FGUTPP_7,
      FGUTPP_12,
      FGUTPP_18,
      FGUTPP_2,
      FGUTPP_8,
      FGUTPP_13,
      FGUTPP_19,
      FGUTPP_3,
      FGUTPP_9,
      FGUTPP_14,
      FGUTPP_1,
      FGUTPP_4,
      FGUTPP_15,
      FGUTPP_20,
      FGUTPP_5,
    ],
  },
  {
    album: "PUJA",
    urls: [
      _20161007_170015_,
      _20161007_170051_,
      _20161007_170911_,
      _20161007_170021_,
      _20161007_170053_,
    ],
  },
  {
    album: "RVUNL - 160 MW RAMGARH",
    urls: [
      _166KV_SWITCHGEAR,
      GTG_BLDG_GTG_TRANSFORMER,
      _1BYPASS_STACK,
      GTGBuilding_Bypass_Stack,
      _1GTG,
      HRSG_GTG_BLDG_BY_PASS_STACKPIPE_RACK,
      _1GT_OFF_BASE_EQUP,
      HRSG_MAIN_STACK,
      _1HRSG,
      over_all,
      _1UAT___GTG,
      RVUNL__1,
      _2_20KVswitch_Yard,
      RVUNL__2,
      _2_20KV_Switch_Yard,
      RVUNL,
    ],
  },
  {
    album: "RVUNL - 330 MW DHOLPUR",
    urls: [
      _160_MVA_GTPnl_charged,
      _160MVA_GT_U,
      _160_MVA_with_Bus_duct_upto_GCB,
      _2_20_KV_SYD_2,
      ACW_Pumps___Piping_works,
      Aircompressorbuilding,
      Boiler_erection_BHEL,
      CCW_Compressor_house,
      CCW_PH_Compressor_room,
      Chimney_BHEL,
      Clarifier_PH_pump_erection,
      Clarifier_reactor_tank__1,
      Clarifier_reactor_tank__2,
      Cooling_Tower_comp_2,
      Cooling_Tower,
      Deggased_water_storage_tank__Fab,
      DM_plant_area_Pumps,
      DM_Plant_Building_with_DM_Tanks___Equipments,
      DM_Plant__Erection_of_Tanks,
      DM_Plant,
      DM_Plant__Pumps_erection,
      DM_Plant_tanks,
      DM_Plant_vessels,
      DM_Tank_Erection,
      Dm_Tanks_painting,
      Fire_Fighting_Line_for_UAT___GT,
      Frontal_Piping,
      FRP_Cooling_towers,
      GCB_with_Busduct_from_GT_to_GCB,
      GTG_SWGR_Charging_Pooja,
      Lighting_works_Swyd_CR,
      LP_Piping___Cable_trays,
      LP_Piping_Cable_trays_Road___Drainage,
      PHE,
      Pipe_Rack_FDN_BFP_Build_Cooling_Tower_1,
      Pipe_racks_with_LP_piping_erct,
      Pipe_Rack_with_erect_of_pipes,
      RWR_Comp__2,
      RWR_PH_with_stop_log_gate,
      STG_B_row_col,
      STG_Building,
      STG__Building,
      STG_Control_room_Deck___turbine_hall,
      STG_CR_Floor_EL_107,
      STG_CR_Floor_EL_1_1_15M,
      STG_Deck_CMD_visit,
      STG_Deck,
      STG_Machine_Hall,
      STG_MCR___SYD_CR,
      STG_SWGR_Room_Panel_charging_1,
      Switchyard_with_control_room,
      Swyd_ACDB___Batter_Charger_Pnl_charged,
      Swyd_CR_Lighting_works,
      Swyd_with_control_room,
      SYD_Control_room,
      SYD_Eqpt_Erection,
      SYD_Erection,
    ],
  },
]
