import React, { useEffect } from "react"
import { Accordion } from "react-bootstrap"
import { Card } from "react-bootstrap"
import Layout from "../components/layout"
import * as styles from "./gallery.module.css"
import { Figure } from "react-bootstrap"
import { photos } from "../components/companyGallery";
import { Seo } from "../components/seo";

const Gallery = () => {
  const width = 400;
  useEffect(() => {
    console.log(photos);
  }, [])

  return (
    <Layout>
      <div className="container-fluid">
        <div className={styles.container}>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              borderRadius: "5px",
              padding: "0 10px",
              borderBottom: "2px solid #ff5f13",
            }}
          >
            <h1>Gallery</h1>
          </div>
        </div>
        {photos.map((album, index) => (
          <Accordion className="mt-2 mb-2" defaultActiveKey="0" key={index}>
            {/* <Card key={index + 1}> */}
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header as={Card.Header} key={index + 2}>
                {album.album}
              </Accordion.Header>
              <Accordion.Body key={index + 3}>
                {/* <Card.Body key={index + 4}> */}
                {album.urls.map((url, i) => (
                  <Figure style={{ padding: '0 10px' }} key={i}>
                    <Figure.Image
                      width={width}
                      height={180}
                      alt="171x180"
                      src={url}
                      key={i + 1}
                    />
                  </Figure>
                  // <StaticImage width={width} height={180} key={i + 1} src={`../images/OFFICE/building.jpg`} alt="A dinosaur" />
                ))}
                {/* </Card.Body> */}
              </Accordion.Body>
            </Accordion.Item>
            {/* </Card> */}
          </Accordion>
        ))}
      </div>
    </Layout>
  )
}

export default Gallery


export const Head = () => (
  <Seo title='Gallery' />
)